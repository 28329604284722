import React from "react"
import Layout from "../../components/Layout"

const Resettling = () => {
  return (
    <Layout>
      <div className="resources_for_sponsorship_groups__picture" />
      <section className="sponsorship__detail">
        <h1 className="sponsorship__detail-title">
          Resources for Sponsorship Groups
        </h1>
        <h2>Categories of Refugee Sponsorship Program</h2>
        <h3>1. Government-Assisted Refugees (GAR) Program</h3>
        <p>
          Refugees are referred to Canada for resettlement by the United Nations
          Refugee Agency (UNHCR) or another referral organization. Individuals
          cannot apply directly. The government is responsible for financial and
          settlement support for their first year in Canada.
        </p>
        <h3>2. Private Sponsorship of Refugees (PSR) Program</h3>
        <p>
          If you know a refugee who is overseas you can sponsor them through
          either the &ldquo;Group of 5&rdquo; sponsorship stream or you can work
          with a Sponsorship Agreement Holder (SAH) to resettle a refugee in
          Canada. Many church denominations in Canada are SAH&apos;s.
        </p>
        <h3>
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/private-sponsorship-program/blended-visa-office-program.html"
            target="_blank"
            rel="noreferrer"
          >
            3. Blended Visa Office-Referred (BVOR) Program
          </a>
        </h3>
        <p>
          Matches refugees identified by the UNHCR with private sponsors in
          Canada. The financial costs of sponsorship are shared between the
          Canadian government and the sponsorship group. The sponsors are
          responsible for all the settlement support.
        </p>
        <h2>
          The following information is intended for Private Sponsors who are
          interested in sponsoring and resettling refugees from outside of
          Canada.
        </h2>
        <h3>Who Can Sponsor a Refugee?</h3>
        <p>
          You must be part of a group to sponsor a refugee. You can&apos;t
          sponsor a refugee on your own. You must be part of one of the
          following groups in Canada.
        </p>
        <ul className="sponsorship__list">
          <li>
            <p>
              <strong>Sponsorship Agreement Holder</strong>
            </p>
            <p>
              A Sponsorship agreement holder (SAH) signs agreements to sponsor
              and support refugees when they come to Canada.
            </p>
            <p>
              As a SAH, you can also work with other groups in the community to
              sponsor refugees under your agreement. These groups are called
              &ldquo;Constituent Groups.&rdquo;
            </p>
          </li>
          <li>
            <p>
              <strong>
                <a
                  href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/private-sponsorship-program/groups-five/eligibility.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Group of Five
                </a>
              </strong>
            </p>
            <p>
              A Group of Five (G5) is a group of 5 or more Canadian citizens or
              permanent residents who come together to sponsor a refugee(s) and
              help them settle in their communities. The sponsorship group will
              have to prove to the government that they have the necessary
              resources and a detailed plan for how to support the newcomer when
              they arrive
            </p>
            <p>
              Groups of Five can only sponsor people who have already been
              formally and officially recognized as a refugee in their country
              of asylum and who are able to provide proof of this status.
            </p>
          </li>
          <li>
            <p>
              <strong>
                <a
                  href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/private-sponsorship-program/community-sponsors/eligibility.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Community Sponsors
                </a>
              </strong>
            </p>
            <p>
              Community Sponsors are organizations, associations or corporations
              that sponsor refugees to come to Canada.
            </p>
          </li>
        </ul>
        <h3>1. Government-Assisted Refugee (GAR) Program</h3>
        <p>
          Under the Government-Assisted Refugee (GAR) Program, refugees are
          referred to Canada for resettlement by the United Nations Refugee
          Agency (UNHCR) or another referral organization.{" "}
          <strong>Individuals cannot apply directly.</strong>
        </p>
        <p>
          You must register for refugee status with the UNHCR or state
          authorities to be considered by a referral organization.
        </p>
        <p>
          A GAR&apos;s initial resettlement in Canada is entirely supported and
          funded by the Government of Canada (or in the case of Quebec, the
          province of Quebec.) This support is delivered by non-governmental
          agencies called service provider organizations funded by IRCC
          (Immigration, Refugee and Citizenship Canada.)
        </p>
        <p>
          GARs{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-within-canada/government-assisted-refugee-program.html"
            target="_blank"
            rel="noreferrer"
          >
            receive support
          </a>{" "}
          for up to one year from the date they arrive in Canada, or until they
          are able to support themselves, whichever happens first.
        </p>
        <p>
          To learn more about GAR, click{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/government-assisted-refugee-program.html"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
        </p>
        <h3>2. Private Sponsorship of Refugees (PSR)</h3>
        <p>
          <strong>
            Canadians can privately sponsor refugees from outside of Canada.
            Groups that can sponsor a refugee(s) are:
          </strong>
        </p>
        <p>
          <strong>(A) Sponsorship Agreement Holders (SAHs): </strong>
          Incorporated organizations that have signed a formal sponsorship
          agreement with IRCC. Most current SAHs are religious organizations,
          ethnocultural groups, or humanitarian organizations. SAHs, which may
          be local, regional or national, assume overall responsibility for the
          management of sponsorships under their agreement. Organizations
          entering into a sponsorship agreement with IRCC generally submit
          several refugee sponsorships a year.
        </p>
        <p>
          <strong>(A-1) Constituent Groups (CG): </strong>A SAH can authorize CG
          to sponsor under its agreement and provide support to the refugees.
          Each SAH sets its own criteria for recognizing CG. CG are based in the
          sponsored refugee&apos;s expected community of settlement and must
          have their sponsorship application and settlement plan approved and
          signed by their SAH before the undertaking is submitted to the
          Resettlement Operations Centre in Ottawa (ROC-O). Most church
          organizations that do not have a SAH falls under the Constituent
          Group.
        </p>
        <p>
          <strong>(B) Groups of Five (G5): </strong>
          Five or more Canadian citizens or permanent residents, who are at
          least 18 years of age, live in the expected community of settlement
          and have collectively arranged for the sponsorship of a refugee living
          abroad. These individuals act as guarantors that the necessary support
          will be provided for the full duration of the sponsorship.
        </p>
        <p>
          ROC-O (Resettlement Operations Center - Ottawa) assesses individual
          contributions of group members to the sponsorship. The financial and
          non-financial aspects are considered collectively, as well as the
          settlement plan, before the sponsorship is approved. The group&apos;s
          financial commitment must meet the levels established in the
          Sponsorship Cost Table in{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/guide-sponsor-refugee-groups-five.html"
            target="_blank"
            rel="noreferrer"
          >
            Appendix A of the Guide for Groups of Five to privately sponsor
            refugees (IMM 2200)
          </a>
          .
        </p>
        <p>
          <strong>(C) Community Sponsors (CSs): </strong>
          Any organization, association or corporation located in the community
          where the refugees are expected to settle can make an organizational
          commitment to sponsor. CSs must undergo financial and settlement plan
          assessments by ROC-O each time they wish to sponsor. Like G5s, CSs
          must demonstrate that they are willing and able to commit funds toward
          the sponsorship in line with the levels established in the Sponsorship
          Cost Table in{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/guide-sponsor-refugee-community.html"
            target="_blank"
            rel="noreferrer"
          >
            Appendix A of the Guide for Community Sponsors to privately sponsor
            refugees (IMM 2201)
          </a>
          .
        </p>
        <h3>3. Blended Visa Office-Referred Program (BVOR)</h3>
        <p>
          <strong>The Blended Visa Office-Referred (BVOR) </strong>
          program makes it easier for private sponsors to help the most
          vulnerable refugees. The benefits of the BVOR program are:
        </p>
        <ul className="sponsorship__list">
          <li>reduces the financial burden on private sponsors</li>
          <li>
            connects sponsors with refugees we&apos;ve already screened and
            interviewed, making them ready to travel sooner
          </li>
          <li>
            The BVOR program works with refugee referral organizations, like the{" "}
            <a
              href="https://www.unhcr.org/en-us/resettlement.html?query=resettlement"
              target="_blank"
              rel="noreferrer"
            >
              United Nations Refugee Agency (UNHCR)
            </a>
            . Referral organizations identify refugees for resettlement. After
            we screen the refugees, the BVOR program connects them with private
            sponsors. Screening includes security and medical checks.
          </li>
        </ul>
        <p>
          <strong>
            To support sponsors, IRCC offers up to 6 months of financial support
            after refugees arrive in Canada.
          </strong>
        </p>
        <p>
          <strong>Private sponsors on the other hand:</strong>
        </p>
        <ul className="sponsorship__list">
          <li>are responsible for associated start-up costs</li>
          <li>need to give up to 6 months of financial support</li>
          <li>need to give up to 1 year of social and emotional support</li>
          <li>
            <strong>don&apos;t</strong> need to cover the cost of BVOR
            refugees&apos; health care
          </li>
          <li>
            BVOR refugees get temporary health care benefits through the{" "}
            <a
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-within-canada/health-care/interim-federal-health-program.html"
              target="_blank"
              rel="noreferrer"
            >
              Interim Federal Health Program (IFHP)
            </a>{" "}
            until they become eligible for provincial or territorial health
            insurance.
          </li>
        </ul>
        <h3>
          What refugees need to know about Private Sponsorship of Refugees
          Program
        </h3>
        <p>
          If you are a Privately Sponsored Refugee (PSR) you can expect that
          your sponsorship group will:
        </p>
        <ul className="sponsorship__list">
          <li>help you find a place to live</li>
          <li>provide you with initial financial support</li>
          <li>provide social and emotional support</li>
          <li>assist you to find food and clothing</li>
          <li>assist you in finding employment</li>
          <li>help you register your school aged children in a local school</li>
          <li>support you in your transition to life in Canada</li>
        </ul>
        <p>
          As a PSR, the sponsorship group must support you financially for 1
          year after you arrive or until you can support yourself, whichever
          comes first.
        </p>
        <p>
          There are no processing fees for these applications and you{" "}
          <strong>don&apos;t</strong> need to pay your sponsor for the cost of
          helping you settle. Sponsors are not allowed to ask you for money to
          help you be resettled.
        </p>
        <h3>Sponsorship Agreement Holders (SAH)</h3>
        <ul className="sponsorship__list">
          <li>
            Many organizations have signed sponsorship agreements with the
            Government of Canada to help support refugees when they resettle in
            Canada. These organizations are called Sponsorship Agreement Holders
            (SAH). Click here for the IRCC approved{" "}
            <a
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/private-sponsorship-program/agreement-holders/holders-list.html"
              target="_blank"
              rel="noreferrer"
            >
              list of SAHs
            </a>
            .
          </li>
          <li>
            Because only SAHs have signed agreements,{" "}
            <a
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/private-sponsorship-program/groups-five.html"
              target="_blank"
              rel="noreferrer"
            >
              Groups of Five
            </a>{" "}
            and{" "}
            <a
              href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/private-sponsorship-program/community-sponsors.html"
              target="_blank"
              rel="noreferrer"
            >
              Community Sponsors
            </a>
            , which are also groups of volunteers that sponsor refugees, do not
            appear on the list.
          </li>
        </ul>
        <h3>Groups of Five (G5)</h3>
        <ul className="sponsorship__list">
          <li>
            A Group of Five (G5) is five or more Canadian citizens or permanent
            residents who have arranged to sponsor a refugee living abroad to
            come to Canada.
          </li>
          <li>
            G5s may only sponsor applicants who have been formally recognized as
            refugees by either the United Nations Refugee Agency (UNHCR) or a
            foreign state. The principal applicant MUST already have refugee
            status. Having refugee status means that an authorized body has
            found that an individual meets its refugee definition. This
            authorized body can either be the UNHCR or the government of the
            country (i.e., foreign state) where the refugee is now living.
          </li>
          <li>
            You must include the document proving recognized refugee status with
            the refugee sponsorship application you submit.
          </li>
          <li>
            For more information, see the{" "}
            <a
              href="https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/guide-private-sponsorship-refugees-program.html"
              target="_blank"
              rel="noreferrer"
            >
              Guide to the Private Sponsorship of Refugees Program
            </a>
            .
          </li>
        </ul>
        <h3>Community Sponsors (CS)</h3>
        <ul className="sponsorship__list">
          <li>
            Some community organizations can sponsor refugees to come to Canada.
          </li>
          <li>
            The organization must agree to give emotional and income support to
            the refugees for the full sponsorship period. This is usually one
            year.
          </li>
          <li>
            Community Sponsors may only sponsor applicants who are recognized as
            refugees by either the United Nations Refugee Agency (UNHCR) or a
            foreign state.
          </li>
          <li>
            The principal applicant MUST already have refugee status. Having
            refugee status means that an authorized body has found that a person
            meets its refugee definition. This authorized body can either be the
            UNHCR or the government of the country (i.e., foreign state) where
            the refugee is now living.
          </li>
          <li>
            You must include the document proving recognized refugee status with
            the refugee sponsorship application you submit.
          </li>
        </ul>
        <h3>How to form a Private Sponsorship of Refugees (PSR) Group</h3>
        <h4>Sponsorship Agreement Holders (SAH)</h4>
        <p>
          Interested organizations can request an application to become a SAH by
          writing to{" "}
          <a
            href="mailto:IRCC.INPSR-PPPRRI.IRCC@cic.gc.ca"
            target="_blank"
            rel="noreferrer"
          >
            IRCC.INPSR-PPPRRI.IRCC@cic.gc.ca
          </a>
          .
        </p>
        <p>
          SAHs must be incorporated organizations and must be located in Canada.
          Generally, new SAH applicants have sponsorship experience and expect
          to sponsor multiple refugee cases each year. Applicant organizations
          must have personnel and finances available to ensure the settlement
          needs of the sponsored refugees are in place before their arrival, and
          must be able to provide financial statements for the most recent 2
          year period.
        </p>
        <h4>Constituent Groups (CG)</h4>
        <p>
          Each SAH sets its own criteria for recognizing CGs. Interested parties
          should{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/private-sponsorship-program/agreement-holders/holders-list.html"
            target="_blank"
            rel="noreferrer"
          >
            contact a SAH
          </a>{" "}
          directly to inquire about sponsoring under its auspices. Click here to
          find IRCC approved{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/private-sponsorship-program/agreement-holders/holders-list.html"
            target="_blank"
            rel="noreferrer"
          >
            list of SAHs
          </a>
          .
        </p>
        <h4>Groups of Five (G5)</h4>
        <p>
          At least 5 individuals must be eligible to sponsor and willing to
          contribute to the requirements of sponsorship. At least 3 group
          members must contribute funds towards the sponsorship, and each group
          member contributing funds must complete a personal{" "}
          <a
            href="https://www.canada.ca/content/dam/ircc/migration/ircc/english/pdf/kits/forms/imm5373be.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Financial Profile form (IMM 5373B)
          </a>
          .
        </p>
        <p>
          The sponsoring group must collectively complete a Sponsorship
          Undertaking and{" "}
          <a
            href="https://www.canada.ca/content/dam/ircc/migration/ircc/english/pdf/kits/forms/imm5670e.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Settlement Plan - Groups of Five (G5) (IMM 5670)
          </a>
          .
        </p>
        <h4>Community Sponsor (CS)</h4>
        <p>
          An organization that decides to participate in refugee sponsorship and
          provides statements demonstrating the ability to meet the required
          financial obligations. Individual co-sponsors formally partnering with
          a CS who are contributing personal income towards the sponsorship must
          complete a personal{" "}
          <a
            href="https://www.canada.ca/content/dam/ircc/migration/ircc/english/pdf/kits/forms/imm5373be.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Financial Profile form (IMM 5373B)
          </a>
          . The sponsoring group must collectively complete a Sponsorship
          Undertaking and{" "}
          <a
            href="https://www.canada.ca/content/dam/ircc/migration/ircc/english/pdf/kits/forms/imm5663e.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Settlement Plan - Community Sponsor (CS) (IMM 5663)
          </a>
          .
        </p>
        <h4>Co-sponsor</h4>
        <p>
          Interested individuals or organizations should{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-outside-canada/private-sponsorship-program/agreement-holders/holders-list.html"
            target="_blank"
            rel="noreferrer"
          >
            contact a SAH
          </a>
          , a CG or a CS in their area to inquire about partnering in the
          private sponsorship of a refugee. Each SAH, CG or CS has its own
          procedures for screening and approving a co-sponsor as well as for
          establishing the division of responsibilities in the settlement plan.
          The decision to accept an individual or organization as a co-sponsor
          is the choice of the SAH or CS that submits the undertaking.
        </p>
        <p>
          To apply, use the{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/application-refugee-sponsorship.html"
            target="_blank"
            rel="noreferrer"
          >
            sponsorship kit for SAH/CGs, G5s or CSs
          </a>{" "}
          as well as the{" "}
          <a
            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/applying-convention-refugee-humanitarian-protected-person-abroad.html"
            target="_blank"
            rel="noreferrer"
          >
            Application for Convention Refugees Abroad and
            Humanitarian-protected Persons Abroad (IMM 6000)
          </a>{" "}
          (overseas application kit), which the refugee must complete.
        </p>
        <h4>Sponsoring Specific Refugee or Refugee referred Visa Office</h4>
        <p>
          <strong>
            Decide if you want to sponsor a specific refugee or a refugee
            referred by a visa office
          </strong>
        </p>
        <p>
          In some cases, a Community Sponsor may want to sponsor a specific
          refugee or refugee family abroad, such as a:
        </p>
        <ul className="sponsorship__list">
          <li>friend</li>
          <li>member of a family in the community</li>
          <li>a refugee identified by an overseas contact</li>
        </ul>
        <p>
          This is known as{" "}
          <strong>&ldquo;sponsor-referred refugee.&rdquo;</strong>
        </p>
        <p>
          Sponsor-referred refugees must qualify under Canada&apos;s refugee and
          humanitarian resettlement program. Both you and the refugee must fill
          out an application. After you apply, an officer abroad will assess the
          application to decide whether the person you want to sponsor
          qualifies.
        </p>
        <p>
          In other cases, you may ask for a{" "}
          <strong>&ldquo;visa office-referred refugee.&rdquo;</strong>
          These refugees have already been determined to be eligible and have
          already been chosen by an officer abroad. They just need a private
          sponsor before they can come to Canada.
        </p>
        <p>
          You can sponsor a visa office-referred refugee through either the
          Blended Visa Office-Referred (BVOR) program, or the Visa
          Office-Referred (VOR) program.
        </p>
        <h4>Country of asylum class</h4>
        <p>You may be in this class if you:</p>
        <ul className="sponsorship__list">
          <li>
            are outside your home country, or the country where you normally
            live, and
          </li>
          <li>
            have been seriously affected by civil war or armed conflict, or
          </li>
          <li>have been denied basic human rights on an ongoing basis.</li>
        </ul>
        <p>
          Country of asylum class refugees can be privately sponsored. You can
          also be in this class if you have the funds you need to support
          yourself and your family after you arrive in Canada. You will still
          need the UNHCR, a referral organization, or a private sponsorship
          group to refer you.
        </p>
        <h4>To learn more about private sponsorship of refugees</h4>
        <ul className="sponsorship__list">
          <li>
            <a href="https://www.rstp.ca/en/" target="_blank" rel="noreferrer">
              Refugee Sponsorship Training Program
            </a>
          </li>
          <li>
            <a
              href="https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/guide-private-sponsorship-refugees-program.html"
              target="_blank"
              rel="noreferrer"
            >
              About Private Sponsorship of Refugees Program
            </a>
          </li>
        </ul>
        <h4>FAQ (for Privately Sponsored Refugees)</h4>
        <p>
          IRCC maintains a website HELP CENTER to help sponsors and refugee
          claimants with FAQ. Click{" "}
          <a
            href="https://www.cic.gc.ca/english/helpcentre/results-by-topic.asp?top=11"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{" "}
          to find the questions that you may have
        </p>
      </section>
    </Layout>
  )
}

export default Resettling
